/**
 * 方案详情内价格显示项数据
 * @param selectedPriceDisplayScheme 这个参数是一个数组 0 只显示单人价格  1 只显示 团组价格
 */
export const schemeDetailsPriceDisplayStrategy = function (selectedPriceDisplayScheme) {
    if (!selectedPriceDisplayScheme || selectedPriceDisplayScheme.length === 0) {
       return {
           SINGLE_PERSON: false,
           GROUP_PRICE: false,
           ALL: false,
           // 个人信息是否展示
           PERSONAL_INFORMATION: false,
           // 公司信息是否展示
           COMPANY_INFORMATION: false
       }
    }
    // 单人价格
    let SINGLE_PERSON = false
    // 团组价格
    let GROUP_PRICE = false
    // 个人信息
    let PERSONAL_INFORMATION = false
    // 公司信息
    let COMPANY_INFORMATION = false
    // 全都显示
    let ALL = false
    for (let i = 0; i < selectedPriceDisplayScheme.length; i++) {
        if (Number(selectedPriceDisplayScheme[i]) === 0) {
            SINGLE_PERSON = true
        }
        if (Number(selectedPriceDisplayScheme[i]) === 1) {
            GROUP_PRICE = true
        }
        if (Number(selectedPriceDisplayScheme[i]) === 2) {
            PERSONAL_INFORMATION = true
        }
        if (Number(selectedPriceDisplayScheme[i]) === 3) {
            COMPANY_INFORMATION = true
        }
    }
    if (SINGLE_PERSON && GROUP_PRICE) {
        ALL = true;
    }
    return {
        SINGLE_PERSON: SINGLE_PERSON,
        GROUP_PRICE: GROUP_PRICE,
        ALL: ALL,
        PERSONAL_INFORMATION: PERSONAL_INFORMATION,
        COMPANY_INFORMATION: COMPANY_INFORMATION
    }
}


/**
 * 团组详情内价格显示项数据
 * @param offerDisplayScheme 0 只显示单人价格  1 只显示 团组价格 2 显示单价
 */
export const priceDisplayWithinGroupDetailsStrategy = function (offerDisplayScheme) {
    if (!offerDisplayScheme || offerDisplayScheme.length === 0) {
        return {
            SINGLE_PERSON: false,
            GROUP_PRICE: false,
            UNIT_PRICE_DISPLAY: false,
            ALL: false,
            DETAIL_PRICE:false
        }
    }
    // 单人价格
    let SINGLE_PERSON = false
    // 团组价格
    let GROUP_PRICE = false
    // 单价显示
    let UNIT_PRICE_DISPLAY = false
    // 全都显示
    let ALL = false
    //详细报价按钮是否显示
    let DETAIL_PRICE
    for (let i = 0; i < offerDisplayScheme.length; i++) {
        if (Number(offerDisplayScheme[i]) === 0) {
            SINGLE_PERSON = true
        }
        if (Number(offerDisplayScheme[i]) === 1) {
            GROUP_PRICE = true
        }
        if (Number(offerDisplayScheme[i]) === 2) {
            UNIT_PRICE_DISPLAY = true
        }
    }
    if (SINGLE_PERSON && GROUP_PRICE && UNIT_PRICE_DISPLAY) {
        ALL = true
    }
    DETAIL_PRICE = SINGLE_PERSON || GROUP_PRICE || UNIT_PRICE_DISPLAY || ALL ? true : false
    return {
        SINGLE_PERSON: SINGLE_PERSON,
        GROUP_PRICE: GROUP_PRICE,
        UNIT_PRICE_DISPLAY: UNIT_PRICE_DISPLAY,
        ALL: ALL,
        DETAIL_PRICE:DETAIL_PRICE
    }
}


/**
 * H5 团组详情页面类别显示
 */
export const h5groupDetailsStrategy = function (h5DisplayScheme) {
    if (!h5DisplayScheme || h5DisplayScheme.length === 0) {
        return {
            // 行程地图
            ITINERARY_MAP: false,
            //特色推荐
            FEATURED_RECOMMENDATIONS: false,
            //参考酒店
            REFERENCE_HOTEL: false,
            //参考餐厅
            REFERENCE_RESTAURANT:false,
            // F
            F: false
        }
    }
    // 行程地图
    let ITINERARY_MAP = false
    // 特色推荐
    let FEATURED_RECOMMENDATIONS = false
    // 参考酒店
    let REFERENCE_HOTEL = false
    // 参考餐厅
    let REFERENCE_RESTAURANT = false
    // F
    let F = false
    for (let i = 0; i < h5DisplayScheme.length; i++) {
        if (Number(h5DisplayScheme[i]) === 4) {
            ITINERARY_MAP = true
        }
        if (Number(h5DisplayScheme[i]) === 5) {
            FEATURED_RECOMMENDATIONS = true
        }
        if (Number(h5DisplayScheme[i]) === 6) {
            REFERENCE_HOTEL = true
        }
        if (Number(h5DisplayScheme[i]) === 7) {
            REFERENCE_RESTAURANT = true
        }
        if (Number(h5DisplayScheme[i]) === 8) {
            F = true
        }
    }
    return {
        ITINERARY_MAP: ITINERARY_MAP,
        FEATURED_RECOMMENDATIONS: FEATURED_RECOMMENDATIONS,
        REFERENCE_HOTEL: REFERENCE_HOTEL,
        REFERENCE_RESTAURANT: REFERENCE_RESTAURANT,
        F:F
    }
}
