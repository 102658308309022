var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Budget-Content" }, [
    _c(
      "div",
      {
        staticClass: "Left",
        style: { display: _vm.detailFlag ? "block" : "none" },
      },
      [
        _vm.budget.totalPriceNotRate > 0 &&
        (_vm._obtainPriceDisplayStatus().ALL ||
          _vm._obtainPriceDisplayStatus().UNIT_PRICE_DISPLAY)
          ? _c(
              "span",
              {
                style: {
                  fontSize: _vm.fontSizeChange(
                    _vm.budget.totalPriceNotRate.toFixed(2).length,
                    2
                  ),
                },
              },
              [
                _vm._v(
                  _vm._s(Math.trunc(_vm.budget.totalPriceNotRate)) +
                    " " +
                    _vm._s(_vm.budget.currency)
                ),
              ]
            )
          : _vm.budget.totalPriceNotRate <= 0
          ? _c("span", [_vm._v("免费")])
          : _vm._e(),
        _c("img", { attrs: { src: _vm.budget.imageUrl, alt: "" } }),
      ]
    ),
    _c("div", { staticClass: "Right" }, [
      _c("div", { staticClass: "R-One R" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.jumpMap(_vm.day)
              },
            },
          },
          [_vm._v(_vm._s(_vm.budget.resourceNameCn))]
        ),
        _vm.budget.timeStr
          ? _c(
              "span",
              {
                staticClass: "R-Two",
                staticStyle: { "margin-left": "0.1rem" },
              },
              [_vm._v(_vm._s(_vm.budget.timeStr))]
            )
          : _vm._e(),
        _c(
          "span",
          {
            staticClass: "R-Two",
            staticStyle: { "font-weight": "400", "margin-left": "0.2rem" },
          },
          [_vm._v(_vm._s(_vm.budget.remark))]
        ),
      ]),
      _c("div"),
      _c("div", { style: { display: _vm.detailFlag ? "block" : "none" } }, [
        _c(
          "div",
          { staticClass: "R-Three R" },
          [
            _vm.budget.addressCn
              ? _c("van-icon", { attrs: { name: "location-o" } })
              : _vm._e(),
            _c("span", { staticClass: "ellipsis" }, [
              _vm._v(_vm._s(_vm.budget.addressCn)),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "R-Four R" }, [
          _c("span", { staticClass: "ellipsis" }, [
            _vm._v(_vm._s(_vm.budget.introduction)),
          ]),
        ]),
        _c(
          "span",
          { staticClass: "R-Five R" },
          _vm._l(_vm.budget.stockDetail, function (info, infoIndex) {
            return _c("span", { key: infoIndex }, [
              _c("span", [_vm._v(_vm._s(info.budgetType) + " ")]),
              _c("span", [_vm._v(_vm._s(info.timeDesc) + " ")]),
              _c(
                "span",
                { staticStyle: { color: "#000000", "font-weight": "700" } },
                [_vm._v(_vm._s(info.productNameCn))]
              ),
              _c("span", [_vm._v(_vm._s(info.stockDesc))]),
              _vm._obtainPriceDisplayStatus().UNIT_PRICE_DISPLAY
                ? _c(
                    "span",
                    { staticStyle: { color: "#000000", "font-weight": "700" } },
                    [
                      _vm._v(
                        _vm._s(info.ratio) +
                          _vm._s(info.currency) +
                          _vm._s(info.priceCompany) +
                          "/" +
                          _vm._s(info.useTime)
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "rgb(142, 43, 11)",
                    "font-weight": "700",
                  },
                },
                [_vm._v(" " + _vm._s(info.stockNumber) + " ")]
              ),
              _c(
                "span",
                { staticStyle: { color: "#000000", "font-weight": "700" } },
                [_vm._v(_vm._s(info.priceCompany))]
              ),
              infoIndex + 1 < _vm.budget.stockDetail.length
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        color: "rgb(2, 160, 198)",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v(" | ")]
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }