<template>
  <div class="Budget-Content">
    <div class="Left" :style="{ display: (detailFlag ? 'block' : 'none') }">
      <span :style="{ fontSize: fontSizeChange(budget.totalPriceNotRate.toFixed(2).length, 2) }"
        v-if="budget.totalPriceNotRate > 0 && (_obtainPriceDisplayStatus().ALL || _obtainPriceDisplayStatus().UNIT_PRICE_DISPLAY)">{{
          Math.trunc(budget.totalPriceNotRate) }} {{ budget.currency }}</span>
      <span v-else-if="budget.totalPriceNotRate <= 0">免费</span>
      <img :src="budget.imageUrl" alt="">
    </div>
    <div class="Right">
      <div class="R-One R">
        <span @click="jumpMap(day)">{{ budget.resourceNameCn }}</span>
        <span style="margin-left: 0.1rem;" class="R-Two" v-if="budget.timeStr">{{budget.timeStr}}</span>
        <!-- <span style="margin-left: 0.1rem;" class="R-Two" v-if="budget.timeStr">{{dateFormatHM(getTimeStamp(budget.timeStr.split('-')[0]))+ '-' + dateFormatHM(getTimeStamp(budget.timeStr.split('-')[1]))}}</span> -->
        <span class="R-Two" style="font-weight: 400;margin-left: 0.2rem;">{{ budget.remark }}</span>
        <!-- <van-rate v-if="budget.star > 0" v-model="budget.star" :count="budget.star" /> -->
      </div>
      <div>
      </div>
      <div :style="{ display: (detailFlag ? 'block' : 'none') }">
        <!-- <div class="R-Two R" >
        <span>{{ budget.timeStr }}</span>
      </div> -->
        <div class="R-Three R">
          <van-icon v-if="budget.addressCn" name="location-o" />
          <span class="ellipsis">{{ budget.addressCn }}</span>
        </div>
        <div class="R-Four R">
          <span class="ellipsis">{{ budget.introduction }}</span>
        </div>
        <span class="R-Five R">
          <span v-for="(info, infoIndex) in budget.stockDetail" :key="infoIndex">
            <span>{{ info.budgetType }} </span>
            <span>{{ info.timeDesc }} </span>
            <span style="color: #000000;font-weight: 700;">{{ info.productNameCn }}</span>
            <span>{{ info.stockDesc }}</span>
            <span v-if="_obtainPriceDisplayStatus().UNIT_PRICE_DISPLAY" style="color: #000000;font-weight: 700;">{{
              info.ratio }}{{ info.currency }}{{ info.priceCompany }}/{{ info.useTime }}</span>
            <span style="color: rgb(142, 43, 11);font-weight: 700;"> {{ info.stockNumber }} </span>
            <span style="color: #000000;font-weight: 700;">{{ info.priceCompany }}</span>
            <span v-if="infoIndex + 1 < budget.stockDetail.length" style="color: rgb(2, 160, 198);font-weight: bold;"> |
            </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { priceDisplayWithinGroupDetailsStrategy } from "@/views/plan/model/PriceShow";
import {mapMutations, mapState} from "vuex";
import {dateFormatHM} from '@/utils/unit'

export default {
  name: '',
  components: {

  },
  props: ['budget', 'detailFlag', 'individual','day'],
  // 定义属性
  data() {
    return {
      dateFormatHM,
      star: 3
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    ...mapState('collection', ['priceDisplay']),
    _obtainPriceDisplayStatus() {
      return function () {
        return priceDisplayWithinGroupDetailsStrategy(this.priceDisplay.offer.checkOfferItems)
      }
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
      ...mapMutations('collection', ['SET_DAY_DATA']),
      jumpMap(day) {
          this.SET_DAY_DATA(day)
          this.$router.push({ path: '/planDays' })
      },
    //转时间搓
    getTimeStamp(time) {
      const [hours, minutes] = time.split(':');
      const currentDate = new Date();
      currentDate.setHours(hours, minutes, 0, 0);
      const timeStamp = currentDate.getTime();
      return timeStamp;
    },
    //字体大小随长度变化
    fontSizeChange(size, width) {
      let num = width / size
      return num > 0.33 ? '0.33rem' : num
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log(this.budget,'看看渲染的数据u88888');
    // let obj = priceDisplayWithinGroupDetailsStrategy(this.priceDisplay.offer.checkOfferItems)
    // console.log(obj,'看见坎坎坷坷');
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.Budget-Content {
  display: flex;

  // min-height: 2rem;
  .R-Two {
    font-size: 0.2rem;
    color: black !important;
    font-weight: 700;
  }

  .Left {
    width: 2rem;
    // height: 2rem;
    border-top-left-radius: 0.2rem;

    >span {
      height: 0.5rem;
      background: white;
      width: auto;
      height: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #db4e1a;
      color: white;
      font-size: 0.33rem;
      border-top-left-radius: 0.2rem;
    }

    >img {
      width: 2rem;
      height: 1.5rem;
      background: #e4e4e4;
    }
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    display: block;
    width: 50vw;
  }

  .Right {
    display: flex;
    flex-direction: column;
    padding: 0.05rem;
    padding-right: 0.2rem;

    .R {
      margin-left: 0.1rem;
      padding-top: 0.05rem;
    }

    .R-Five {
      text-align: justify;
      text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
      text-align-last: left;
      font-size: 0.25rem;
    }

    .R-Four {
      >span {
        color: #686868;
        font-size: 0.18rem;
      }
    }

    .R-Three {
      display: flex;

      /deep/ .van-icon {
        display: flex;
        align-items: center;
        font-size: 0.18rem;
      }

      >span {
        color: #686868;
        font-size: 0.18rem;
      }
    }

    .R-One {
      width: auto;

      /deep/ .van-rate__icon--full {
        color: #db4e1a;
      }

      /deep/ .van-rate__icon {
        font-size: 0.2rem;
      }

      /deep/ .van-rate {
        margin-left: 0.1rem;
      }

      >span {
        font-weight: bold;
        color: #db4e1a;
        margin-top: 0.05rem;
        font-size: 0.3rem;
      }
    }
  }
}</style>