var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-top": "27px" } }, [
    _c("figure", { staticClass: "wave" }, [
      _c("img", {
        staticClass: "plan-pic",
        attrs: { src: _vm.planMap, alt: "" },
      }),
      _c("figcaption", [_vm._v("相框")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }