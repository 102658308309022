<template>
  <!-- 行程分享 -->
  <div class="Plan-Share">
    <header id="header">
      <div @click="handlerSelectClick('全', arrs)" class="btns" :class="current === '全' ? 'active' : ''">
        全
      </div>
      <div @click="handlerSelectClick(keys, item)" class="btns" :class="current === keys ? 'active' : ''"
        v-for="(item, keys) in this.datas" :key="keys">
        {{ types[keys] }}
      </div>
    </header>
    <footer id="footer">
      <div class="price" style="background-color: #D5562E;"
        v-if="_obtainPriceDisplayStatus().ALL || _obtainPriceDisplayStatus().GROUP_PRICE">
        总价：{{ Math.trunc(totalPrice) }}¥
      </div>
      <div class="price" style="background-color: #C63347;"
        v-if="_obtainPriceDisplayStatus().ALL || _obtainPriceDisplayStatus().SINGLE_PERSON">
        人均：{{ Math.trunc(singlePrice) }}¥
      </div>
    </footer>
    <main id="main" :style="{ height: scrollHeight }">
      <div class="Plan-Content" v-for="(day, dayIndex) in planData" :key="dayIndex">
        <div class="PC-One">
          <span class="day_icon PCO-Icon">D{{ day.planDay }}</span>
          <div class="PCO-City-Date">
            <span>{{ day.planDate | dateFormat }}</span>
            <span>{{ day.cityStr }}</span>
          </div>
        </div>
        <div>
        </div>
        <div v-if="current === '全'">
          <div :style="{ display: (axleFlag ? 'block' : 'none') }" class="PCO-Content"
            v-for="(budget, budgetIndex) in day.budgetList" :key="budgetIndex">
            <span style="display: flex;padding: 10px;"
              v-if="budget.state == 11 && (budget.resourceNameCn || budget.remark)">{{ budget.resourceNameCn }}
              <span v-if="budget.timeStr">{{ budget.timeStr }}</span> {{ budget.remark }}</span>
            <BudgetContent v-else :detailFlag="detailFlag" :budget="budget" :individual="individual"></BudgetContent>
          </div>
        </div>
        <div v-else>
          <div v-if="day.budgetList">
            <div :style="{ display: (axleFlag ? 'block' : 'none') }" class="PCO-Content"
              v-for="(budget, budgetIndex) in day.budgetList.filter(n => n.state == current)" :key="budgetIndex">
              <span style="display: flex;padding: 10px;"
                v-if="budget.state == 11 && (budget.resourceNameCn || budget.remark)">{{ budget.resourceNameCn }}
                <span v-if="budget.timeStr">{{ budget.timeStr }}</span> {{ budget.remark }}</span>
              <BudgetContent v-else :detailFlag="detailFlag" :budget="budget" :individual="individual"></BudgetContent>
            </div>
          </div>
        </div>
      </div>
      <!-- 行程图图片 （轴）-->
      <div v-if="detailFlag && !axleFlag">
        <planMapPic :planMap="planMap"></planMapPic>
      </div>
    </main>

    <div class="rightBtnBox">
      <div @click="handlerGoTipClick" class="circleBtns">
        <svg-icon class="goTop" :class="isUd ? 'isRoteT' : 'isRoteF'" iconClass="goTop"></svg-icon>
      </div>
      <div :class="!axleFlag ? 'active' : ''"
        @click="axleFlag = !axleFlag, mapFlag = !mapFlag, detailFlag = true, isUd = false" class="circleBtns">
        轴
      </div>
      <div :class="!detailFlag ? 'active' : ''"
        @click="detailFlag = !detailFlag, mapFlag = !mapFlag, axleFlag = true, isUd = false" class="circleBtns">
        {{ detailFlag ? '简' : ' 详' }}
      </div>
      <!-- <div @click="toMap()" class="circleBtns">
          图
      </div> -->
      <div @click="back()" class="circleBtns">
        返
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import BudgetContent from '@/views/plan/children/BudgetContent'
import { changePlanInfo } from "./model/PlanInfo";
import { getGroup } from '@/utils/unit.js'
import router from '@/router'
import planMapPic from '@/views/plan/children/planMapPic'
import createPlanMap from './children/createPlanMap.vue';
import { dateFormatHM } from '@/utils/unit'

import {
  priceDisplayWithinGroupDetailsStrategy,
  schemeDetailsPriceDisplayStrategy
} from "@/views/plan/model/PriceShow";
import { mapState } from "vuex";

export default {
  name: 'PlanShare',
  components: {
    BudgetContent, planMapPic, createPlanMap
  },
  // 定义属性
  data() {
    return {
      dateFormatHM,
      planMap: '',
      planData: [],
      datas: {},//筛选的类型
      headcount: 1,//总人数
      current: '全',
      scrollHeight: '1000px',
      axleFlag: true,//轴
      detailFlag: true,//详
      mapFlag: true,
      isUd: false,
      mapCityArr: [],
      singlePrice: 0, //单价
      totalPrice: 0, //总价
      priceList: [],//需要计算价格的数组
      arrs: [],//全部的数据数组形式
      types: {
        '0': '酒',
        '1': '会',
        '2': '景',
        '3': '车',
        '4': '餐',
        '5': '人',
        '6': '签',
        '7': '商',
        '8': '玩',
        '9': '服',
        '10': '公',
        '11': '行程描述',
        '12': '服',
        '13': '税费',
        '15': '交'
      },
      // 是否显示单项价格
      individual: false,
      // 是否显示单项总价
      individualTotal: false,
      // 是否显示团组总价
      totalGroupPrice: false
    }
  },
  // 监控data中的数据变化
  watch: {},
  computed: {
    ...mapState('collection', ['priceDisplay','collectionLinkData']),
    _obtainPriceDisplayStatus() {
      return function () {
        console.log("价格显示项", priceDisplayWithinGroupDetailsStrategy(this.priceDisplay.offer.checkOfferItems))
        return priceDisplayWithinGroupDetailsStrategy(this.priceDisplay.offer.checkOfferItems)
      }
    }
  },
  // 方法集合
  methods: {
    mapShow() {
      this.$nextTick(() => {
        this.$refs.createPlanMap.initMap(true)
      })
    },
    toMap() {
      this.$router.push({ name: 'PlanBaiduMap', params: { citys: this.mapCityArr } })
    },
    //返回上一个页面
    back() {
      router.go(-1)
    },
    /**
     * 获取数据
     * @private
     */
    _getData() {
      changePlanInfo({ collectionLinkData: this.collectionLinkData,planId: this.$route.query.planId, budgetMainId: this.$route.query.budgetMainId }).then(pro => {
        this.planData = pro._platDays
        this.mapCityArr = []
        let cityArr = [...(pro._allCitys.filter(u => u.longitude !== '' && u.latitude !== ''))]
        let _filterCitys = cityArr.filter(u => u.countryId == 8)
        if (_filterCitys.length < 3) {
          this.mapCityArr.push(...cityArr.filter(u => u.countryId != 8))
        } else {
          this.mapCityArr.push(...cityArr)
        }
        this.headcount = pro.num
        this.planMap = pro.planMap
        let arr = []
        this.planData.forEach(item => {
          if (item.budgetList) {
            item.budgetList.forEach(item1 => {
              if (Number(item1.state !== 11)) {
                arr.push(item1)
              }
            })
          }
        })
        this.arrs = arr
        this.handlerSelectClick('全', arr)
        this.datas = getGroup(arr, 'state') //分组后的数据
        console.log("seacher", this.datas)
      })
    },
    //筛选按钮
    handlerSelectClick(key, item) {
      this.current = key
      this.$nextTick(() => {
        let topHeight = document.getElementById("main")
        let viewHeight = topHeight.scrollHeight
        topHeight.scrollTo({
          top: 0,
          // behavior: 'smooth'
        })
      })
      this.priceList = item.filter(n => n.totalPrice)
      this.suanPrice()
    },

    //计算价格
    suanPrice() {
      if (this.priceList.length > 0) {
        this.totalPrice = (this.priceList.map(x => x.totalPrice).reduce((m, n) => m + n)).toFixed(2)
        this.singlePrice = (this.totalPrice / this.headcount).toFixed(2)
        console.log("总价", this.totalPrice)
        console.log("单价", this.singlePrice, this.headcount)
      }
    },
    //回到顶部 或底部
    handlerGoTipClick() {
      this.$nextTick(() => {
        let topHeight = document.getElementById("main")
        let viewHeight = topHeight.scrollHeight
        topHeight.scrollTo({
          top: this.isUd ? 0 : viewHeight,
          behavior: 'smooth'
        })
      })
    },
    //滚动事件对象
    handleScroll(e) {
      let viewHeight = document.getElementById("main").scrollHeight
      let topHeight = e.target.scrollTop
      this.isUd = topHeight > viewHeight / 2 ? true : false
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this._getData()
    let viewHeight = document.body.clientHeight //body视图高度
    let headerHeight = document.getElementById("header").offsetHeight
    let footers = document.getElementById("footer")
    let footerHeight = 0
    if (footers) {
      footerHeight = document.getElementById("footer").offsetHeight
    }
    document.getElementById("main").addEventListener("scroll", this.handleScroll);
    if (!this._obtainPriceDisplayStatus().GROUP_PRICE && !this._obtainPriceDisplayStatus().SINGLE_PERSON) {
      footers.style.display = 'none'
      footerHeight = 0
    }
    if (this.$route.query.bar) {
      this.scrollHeight = viewHeight - headerHeight - footerHeight + 'px'
    } else {
      this.scrollHeight = viewHeight - headerHeight - footerHeight - 50 + 'px'
    }
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="scss" scoped>
.rightBtnBox {
  position: absolute;
  /* top: 77px; */
  right: 4px;
  bottom: 63px;

  .circleBtns {
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 0.1rem;
    background: #F5F5F5;
    color: #D5562E;
    font-size: 0.24rem;
  }
}

.active {
  background-color: #CB582E !important;
  color: #fff !important;
  font-weight: bold;
}

.isRoteT {
  transform: rotate(180deg);
  transition: all 0.2s ease;
}

.isRoteF {
  transform: rotate(0deg);
  transition: all 0.2s ease;
}

.day_icon {
  width: 0.5rem;
  height: 0.5rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACUklEQVRYR+2Xy2vUUBTGv3NnyhSlUrSVJOMjxZV20UL/gSkW7KRV3Lh2Vt0LdW3Fre6tG3Wj0KXTTkpRO3sf4EaKVDqMTjKtKCoo83ByJKHJvEp7pwSmi7kQuLm533d+OfcmOSF0uVGX4+NAgC/TSqLP4TfK2vYfWdiNa0MDxyvRCRndvgDWjDoHxqLDPH/GLD6QBfhqKCkBeiyj2xegYCgLBLrD4LvxTHFBFqATXRvA1nV9MFoqjbvBhMBNAqUY/MRx8FQWYC/dMaq8P2X++N3q0QRgGdoSwDdkA3U6j8DpKju3zpk7n31tAGAZKgeGjOxuXwdBByMHeIdsa9cREr6YBc7Hl+28e+4BWEl1EYQ5d62JKK2t2O/c8U7WspFsL509reiOQMrdUwCtaBlrtg5gqDsAhol5VDWLH32zb1eG1Kroe8ZEj+IZ67ns7duzisE1ut2qcx/piKB1L7DDI+pqMUfBICOrmfakbJDDzrOS6joIiZrDk2dXi9kAgEEfQJg/rLG0jnGfwGNtANIGIU30X1L1JQD9BHiwwf9lSLF8m6ndjuO+Yir/nMv62vbro7MH0O1N2APoZaCXgV4GupaB78mTJ8oU+9X4jQ75GxDYWTPqBBhvvYEIXdTS1oZXEdmG8oJBV1srojBBmisifCr/jY2NZHMlDyCfPH0hSpHNIGC9JgyPoaEmFEJMKcuFV0FJ5mUhqVyCoCVmjIYXtdmJiC1muqdl7If+laayfCuh98cGKnqt6ihhQ8Si0cJwrT9P5ma50fvAf8OwQVr9ug7wH/8HmjAEHOudAAAAAElFTkSuQmCC) no-repeat;
  background-size: 0.5rem 0.5rem;
  font-weight: bold;
  color: #db4e1a;
  text-align: center;
  line-height: 0.66rem;
  font-size: 0.2rem;
}

.Plan-Share {
  position: relative;

  #header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 0.24rem 0.24rem 0.14rem 0.24rem;
    flex-wrap: wrap;
    z-index: 100;

    .btns {
      padding: 0.04rem 0.1rem;
      text-align: center;
      background-color: #fff;
      margin-right: 0.1rem;
      color: #CB582E;
      margin-bottom: 0.1rem;
      border-radius: 5px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }

  #main {
    padding: 20px;
    height: 10rem;
    overflow: scroll;

    .Plan-Content {
      border-left: 0.03rem dashed #db4e1a;

      .PCO-Content {
        box-shadow: 0px 0.01rem 0.1rem 0px #DDDDDD;
        width: 98%;
        margin-left: 15px;
        position: relative;
        border-radius: 0.2rem;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .PC-One {
        background: white;
        display: flex;
        position: relative;
        left: -0.25rem;

        .PCO-Icon {}

        .PCO-City-Date {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0.7rem;
          top: 50%;
          transform: translateY(-41%);

          >span:nth-child(1) {
            color: #333;
            margin: 0 0.15rem;
            font-size: 0.27rem;
            width: 2rem;
          }

          >span:nth-child(2) {
            width: 8rem;
            display: inline-block;
            width: 4.25rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }

  #footer {
    height: 0.8rem;
    display: flex;
    align-items: center;
    //position: fixed;
    width: 100%;

    .price {
      flex: 1;
      height: 0.8rem;
      color: #fff;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.32rem;
    }
  }
}
</style>
