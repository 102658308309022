<template>
  <div style="margin-top: 27px;">
    <figure class="wave">
      <img class="plan-pic" :src="planMap" alt="">
      <figcaption>相框</figcaption>
    </figure>  
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import createPlanMap from './createPlanMap.vue';
export default {
  name:  '',
  components: {
    createPlanMap
  },
  // 定义属性
  data() {
    return {
      
    }
  },
  props: {
    'planMap': {
      type: String,
      default: () => {
        return ""
      }
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
  .plan-pic {
    width: 100%;
  }

  .wave {
    //float: left;
    margin: 20px;
    //animation: wave ease-in-out 0.5s infinite alternate;
    //transform-origin: center -20px;
  }

  .wave:hover {
    animation-play-state: paused;
  }

  .wave img {
    //border: 1px solid rgb(213, 86, 46);
    display: block;
    transform: scale(1.21);
  }

  .wave figcaption {
   text-align: center;
  }

.wave:after {
  content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: -10px;
    left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
  }

.wave:before {
  content: '';
  position: absolute;
  top: -23px;
  left: 50%;
  display: block;
  height: 44px;
  width: 47px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  z-index: 16;
}
</style>